import type React from "react";
import ElementByType, { ElementType } from "../ElementByType";
import styles from "./MobileOnly.module.scss";

export interface MobileOnlyProps {
	children?: React.ReactNode;
	wrapper?: ElementType;
}

export default function MobileOnly({
	children,
	wrapper = "div",
}: MobileOnlyProps) {
	return (
		<ElementByType type={wrapper} className={styles.container}>
			{children}
		</ElementByType>
	);
}

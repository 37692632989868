import { Flower, Smiley, Sunburst } from "@/svg";
import styles from "./NavigationDivider.module.scss";

type NavigationDividerVariant = "breadcrumb" | "flower" | "smiley" | "sunburst";

export interface NavigationDividerProps {
	variant?: NavigationDividerVariant;
}

const variantStyle: Record<NavigationDividerVariant, string> = {
	flower: styles.flower,
	smiley: styles.smiley,
	sunburst: styles.sunburst,
	breadcrumb: styles.breadcrumb,
};

export default function NavigationDivider({
	variant = "breadcrumb",
}: NavigationDividerProps) {
	return (
		<li aria-hidden className={variantStyle[variant]}>
			{variant === "flower" ? (
				<Flower className={styles.flower} />
			) : variant === "smiley" ? (
				<Smiley className={styles.smiley} />
			) : variant === "sunburst" ? (
				<Sunburst className={styles.sunburst} />
			) : (
				<></>
			)}
		</li>
	);
}

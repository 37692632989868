"use client";

import type { PhaseProps } from "@/components/types/PhaseProps";
import { trackCustomFilter } from "@/lib/clientSide/tracking";
import {
	PROGRAM_FREE_SLUG,
	PROGRAM_FREE_TITLE,
	PROGRAM_HOBART_SLUG,
	PROGRAM_HOBART_TITLE,
	PROGRAM_LAUNCESTON_SLUG,
	PROGRAM_LAUNCESTON_TITLE,
	PROGRAM_SCHEDULE_SLUG,
	PROGRAM_SCHEDULE_TITLE,
	PROGRAM_SLUG,
	PROGRAM_TODAY_SLUG,
	PROGRAM_TODAY_TITLE,
	PROGRAM_VENUES_SLUG,
	PROGRAM_VENUES_TITLE,
} from "@/lib/config/staticRoutes";
import { slugsPath } from "@/lib/helpers/paths";
import { ProgramPhase } from "@/sanity/schemas/vendored/enums";
import NavigationDivider from "../../navigationDivider/NavigationDivider";
import SecondaryNavigationItem from "../../navigationItem/SecondaryNavigationItem";

export interface ProgramMenuItemsProps extends PhaseProps {
	dividers?: boolean;
}

const todayMenuItem = (
	<SecondaryNavigationItem
		key={PROGRAM_TODAY_SLUG}
		href={slugsPath(PROGRAM_SLUG, PROGRAM_TODAY_SLUG)}
	>
		{PROGRAM_TODAY_TITLE}
	</SecondaryNavigationItem>
);

const programMenuItems = [
	<SecondaryNavigationItem
		key={PROGRAM_HOBART_SLUG}
		href={slugsPath(PROGRAM_SLUG, PROGRAM_HOBART_SLUG)}
		onClick={() => {
			trackCustomFilter({
				label: PROGRAM_HOBART_TITLE,
				action: "click",
				data: { pathName: slugsPath(PROGRAM_SLUG, PROGRAM_HOBART_SLUG) },
			});
		}}
	>
		{PROGRAM_HOBART_TITLE}
	</SecondaryNavigationItem>,
	<NavigationDivider key={`${PROGRAM_HOBART_SLUG}-divider`} variant="flower" />,
	<SecondaryNavigationItem
		key={PROGRAM_LAUNCESTON_SLUG}
		href={slugsPath(PROGRAM_SLUG, PROGRAM_LAUNCESTON_SLUG)}
		onClick={() => {
			trackCustomFilter({
				label: PROGRAM_LAUNCESTON_TITLE,
				action: "click",
				data: { pathName: slugsPath(PROGRAM_SLUG, PROGRAM_LAUNCESTON_SLUG) },
			});
		}}
	>
		{PROGRAM_LAUNCESTON_TITLE}
	</SecondaryNavigationItem>,
	<NavigationDivider
		key={`${PROGRAM_LAUNCESTON_SLUG}-divider`}
		variant="sunburst"
	/>,
	<SecondaryNavigationItem
		key={PROGRAM_FREE_SLUG}
		href={slugsPath(PROGRAM_SLUG, PROGRAM_FREE_SLUG)}
		onClick={() => {
			trackCustomFilter({
				label: PROGRAM_FREE_TITLE,
				action: "click",
				data: { pathName: slugsPath(PROGRAM_SLUG, PROGRAM_FREE_SLUG) },
			});
		}}
	>
		{PROGRAM_FREE_TITLE}
	</SecondaryNavigationItem>,
	<NavigationDivider key={`${PROGRAM_FREE_SLUG}-divider`} variant="smiley" />,
	<SecondaryNavigationItem
		key={PROGRAM_SCHEDULE_SLUG}
		href={slugsPath(PROGRAM_SLUG, PROGRAM_SCHEDULE_SLUG)}
		onClick={() => {
			trackCustomFilter({
				label: PROGRAM_SCHEDULE_TITLE,
				action: "click",
				data: { pathName: slugsPath(PROGRAM_SLUG, PROGRAM_SCHEDULE_SLUG) },
			});
		}}
	>
		{PROGRAM_SCHEDULE_TITLE}
	</SecondaryNavigationItem>,
	<NavigationDivider
		key={`${PROGRAM_SCHEDULE_SLUG}-divider`}
		variant="flower"
	/>,
	<SecondaryNavigationItem
		key={PROGRAM_VENUES_SLUG}
		href={slugsPath(PROGRAM_SLUG, PROGRAM_VENUES_SLUG)}
		onClick={() => {
			trackCustomFilter({
				label: PROGRAM_VENUES_TITLE,
				action: "click",
				data: { pathName: slugsPath(PROGRAM_SLUG, PROGRAM_VENUES_SLUG) },
			});
		}}
	>
		{PROGRAM_VENUES_TITLE}
	</SecondaryNavigationItem>,
];

export default function ProgramMenuItems({
	phase = ProgramPhase.BrandAnnounce,
}: ProgramMenuItemsProps) {
	const items =
		phase >= ProgramPhase.FestivalMode && phase < ProgramPhase.Archive
			? [todayMenuItem, ...programMenuItems]
			: programMenuItems;

	return <>{items}</>;
}

import type React from "react";
import { ElementType } from "./ElementByType";
import DesktopOnly from "./desktopOnly/DesktopOnly";
import MobileOnly from "./mobileOnly/MobileOnly";

export interface MobileDesktopProps {
	children: [React.ReactNode, React.ReactNode];
	wrapper?: ElementType;
}

export default function MobileDesktop({
	children,
	wrapper,
}: MobileDesktopProps) {
	const [mobile, desktop] = children;

	return (
		<>
			<MobileOnly wrapper={wrapper}>{mobile}</MobileOnly>
			<DesktopOnly wrapper={wrapper}>{desktop}</DesktopOnly>
		</>
	);
}

"use client";

import { pathMatches } from "@/lib/navigation/paths";
import classNames from "classnames";
import Link from "next/link";
import { usePathname } from "next/navigation";
import type React from "react";
import styles from "./NavigationItem.module.scss";

export type NavigationItemVariant =
	| "standard"
	| "secondary"
	| "icon"
	| "breadcrumb"
	| "logo"
	| "noStyle";

export type NavigationItemMatch = false | true | "exact";

export type NavigationChildRenderer = (
	match: NavigationItemMatch,
) => React.ReactNode;

export interface NavigationItemProps {
	href: string;
	children?: string | React.ReactNode;
	title?: string;
	/** Link is struck through and un-clickable, actively disabled */
	disabled?: boolean;
	/** Link is not clickable, soft disabled */
	inactive?: boolean;
	variant?: NavigationItemVariant;
	className?: string;
	renderChildren?: NavigationChildRenderer;
	onClick?: (event: React.SyntheticEvent<HTMLAnchorElement>) => any;
}

const variantStyle: Record<NavigationItemVariant, string> = {
	breadcrumb: styles.breadcrumb,
	icon: styles.icon,
	secondary: styles.secondaryItem,
	standard: styles.item,
	logo: styles.logo,
	noStyle: styles.noStyle,
};

export default function NavigationItem({
	href,
	children,
	inactive,
	disabled = inactive,
	renderChildren,
	className: itemClassName,
	title = typeof children === "string" ? children : undefined,
	variant = "standard",
	onClick,
}: NavigationItemProps) {
	const pathname = decodeURI(usePathname() || "");
	const match = pathMatches(href, pathname);
	const exactMatch = pathMatches(href, pathname, true);
	const linkClassName = disabled
		? inactive
			? styles.inactive
			: styles.disabled
		: match
		? exactMatch
			? styles.current
			: styles.inTree
		: styles.active;

	// If render function provided, ignore children
	const customLabel =
		renderChildren && renderChildren(exactMatch ? "exact" : match);

	// Force non-breaking spaces and hyphens (to prevent labels wrapping) when label is a string
	const label =
		customLabel ||
		(typeof children === "string"
			? children.replaceAll(" ", " ").replaceAll("-", "‑")
			: children);

	const target = /^http/.test(href) ? "_blank" : undefined;

	// Mirror link class names on the container
	const containerLinkClassName = styles[`${linkClassName}Container`];

	return (
		<li
			className={classNames(
				variantStyle[variant],
				itemClassName,
				containerLinkClassName,
			)}
		>
			<Link
				className={linkClassName}
				href={disabled ? "#" : href}
				target={target}
				title={title}
				tabIndex={disabled || exactMatch ? -1 : undefined}
				aria-current={exactMatch ? "location" : undefined}
				onClick={(event) => onClick?.(event)}
			>
				{label}
			</Link>
		</li>
	);
}

import type React from 'react';
import ElementByType, {
  ElementType,
} from '../ElementByType';
import styles from './DesktopOnly.module.scss';

export interface DesktopOnlyProps {
  children?: React.ReactNode;
  wrapper?: ElementType;
}

export default function DesktopOnly({
  children,
  wrapper = 'div',
}: DesktopOnlyProps) {
  return (
    <ElementByType type={wrapper} className={styles.container}>
      {children}
    </ElementByType>
  );
}

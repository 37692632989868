import type React from 'react';
import styles from './NavigationRowList.module.scss';
import classNames from 'classnames';

export interface NavigationRowListProps {
  children: React.ReactNode;
  className?: string;
}

export default function NavigationRowList({
  children,
  className,
}: NavigationRowListProps) {
  return (
    <ul className={classNames(styles.list, className)}>
      {children}
    </ul>
  );
}

import type React from 'react';
import classNames from 'classnames';
import styles from './NavigationRow.module.scss';
import NavigationRowList from './NavigationRowList';

export type NavigationRowVariant = 'top' | 'bottom' | 'breadcrumb' | 'centred';

export interface NavigationRowProps {
  children: React.ReactNode;
  label: string;
  className?: string;
  variant?: NavigationRowVariant;
  /**
   * Conditionally wrap items passed in `children` in a `NavigationRowList`, defaults to `true`
   * - allows navigation row to be used as a container without forcing a single top-level list
    */
  wrapWithList?: boolean;
}

/**
 * Map of variants to [container, list?] styles
 */
const variantStyle: Record<NavigationRowVariant, [string, string | undefined]> = {
  top: [styles.top, undefined],
  bottom: [styles.bottom, undefined],
  breadcrumb: [styles.breadcrumb, styles.breadcrumbList],
  centred: [styles.centred, styles.centredList],
};

export default function NavigationRow({
  children,
  className,
  label,
  variant = 'top',
  wrapWithList = true,
}: NavigationRowProps) {
  const [containerVariant, listVariant] = variantStyle[variant];
  const items = (wrapWithList
    ? (
      <NavigationRowList className={classNames(styles.rowList, listVariant)}>
        {children}
      </NavigationRowList>
    )
    : children
  );

  return (
    <nav
      className={classNames(containerVariant, className)}
      aria-label={label}
    >
      {items}
    </nav>
  );
}

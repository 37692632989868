/**
 * Match a path to another
 *
 * @param matcher string or regular expression
 * @param pathname current path name
 * @param exact whether to require an exact match, when matcher is string
 * @returns
 */
export function pathMatches(
  matcher: string | RegExp,
  pathname: string | null,
  exact: boolean = matcher === '/',
): boolean {
  if (pathname === null) {
    return false;
  }

  if (typeof matcher === 'string') {
    if (exact) {
      return pathname === matcher;
    }

    return new RegExp(`^${matcher}(/|$)`).test(pathname);
  }

  return matcher.test(pathname);
}

export function noDefaultExport() {
  throw new Error('Easier than disabling lint rules');
}
